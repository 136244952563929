import React from 'react'

function LogoFacebookIcon(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      height="32px"
      width="32px"
      style={{ margin: '0 0.5rem', verticalAlign: 'middle' }}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221v-49.36c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42.02h62.12l-9.92 64.77h-52.2v156.53C398.1 461.85 480 369.18 480 257.35z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default LogoFacebookIcon
